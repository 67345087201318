<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="6" lg="6">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1">
                                <b-form-input v-model="formData.name" :state="errors[0] ? false : null" />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="6" lg="6">
                    <b-form-group :label="$t('code')">
                        <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1">
                                <b-form-input v-model="formData.code" :state="errors[0] ? false : null" />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col>
                    <ValidationProvider name="headers" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('headers')">
                            <lined-textarea v-model="formData.headers"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{ height: '300px'}"
                                            :validateError="errors[0]">
                            </lined-textarea>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="createForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

// Services
import ExcelImportTemplate from '@/services/ExcelImportTemplate';

// Components
import LinedTextarea from "@/components/elements/LinedTextarea";

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        LinedTextarea,
    },
    data() {
        return {
            formLoading: false,
            formData: {},
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid){
                const formData = new FormData();

                formData.append("name", this.formData.name);
                formData.append("code", this.formData.code);

                const headersArray = this.formData.headers.split(/\n/).map(header => header.trim()).filter(header => header);
                headersArray.forEach((header, index) => {
                    formData.append(`headers[${index}]`, header);
                });

                this.formLoading = true;
                ExcelImportTemplate.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
           }

        },
    }
}
</script>