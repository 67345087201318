<template>
    <div>
        <b-row>
            <b-col md="6">
                <b-form-group :label="$t('name')">
                    <b-input-group ><div class="label-as-input">{{ isNotNullValue(formData.name) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group :label="$t('code')">
                    <b-input-group><div class="label-as-input">{{ isNotNullValue(formData.code) }}</div></b-input-group>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group :label="$t('headers')">
                    <lined-textarea v-model="formData.headers"
                                    :nowrap="false"
                                    :disabled="false"
                                    :styles="{ height: '300px' }">
                    </lined-textarea>
                </b-form-group>
            </b-col>
           
        </b-row>
    </div>
</template>

<script>
    // Services
    import ExcelImportTemplate from '@/services/ExcelImportTemplate';
    
    // Components
    import LinedTextarea from "@/components/elements/LinedTextarea";

    export default {
        components: {
            LinedTextarea
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formData: {
                    headers: null,
                },
                showStatus:null,
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ExcelImportTemplate.get(id)
                            .then(response => {
                                this.formData = response.data.data;
                                this.showStatus=this.formData.status

                               if(this.formData.headers) {
                                    this.formData.headers = this.formData.headers.join("\n")
                                }
                            })
                            .catch(e => {
                                this.showErrors(e)
                            })

            },

        }
    }
</script>
