<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="6" lg="6">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1">
                                <b-form-input v-model="formData.name" :state="errors[0] ? false : null" />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="6" lg="6">
                    <b-form-group :label="$t('code')">
                        <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1">
                                <b-form-input v-model="formData.code" :state="errors[0] ? false : null" />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col>
                   <ValidationProvider name="headers" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('headers')">
                            <lined-textarea v-model="formData.headers"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{ height: '300px' }"
                                            :validateError="errors[0]">
                            </lined-textarea>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Services
import ExcelImportTemplate from '@/services/ExcelImportTemplate';

// Components
import LinedTextarea from "@/components/elements/LinedTextarea";

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        LinedTextarea
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {
                name: null,
                code: null,
                headers: null,
            },
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            ExcelImportTemplate.get(id)
                .then(response => {
                    let data = response.data.data
                    this.formData=data

                    if(this.formData.headers) {
                        this.formData.headers = this.formData.headers.join("\n")
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                const headers = Array.isArray(this.formData.headers)
                    ? this.formData.headers.map(header => header.trim()).filter(header => header)
                    : this.formData.headers.split(/\n/).map(header => header.trim()).filter(header => header);

                const formData = {
                    name: this.formData.name,
                    code: this.formData.code,
                    headers
                };

                this.formLoading = true;

                ExcelImportTemplate.update(this.formId, formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
    }
}
</script>
